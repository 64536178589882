*,
.ui {
  font-family: monospace;
}

body {
  background: url("./img/bg.png");
}

.game-menu {
  border: #fd56e5;
  border-style: double;
  margin: 0 25px 35px;
}

.game-menu.hyper {
  border-color: #62f258;
}

.main-menu-text {
  font-family: "VT323", monospace;
  color: #fd56e5;
  background: #1e1e1e;
  position: relative;
  top: -12px;
  font-size: 24px;
  float: right;
  right: 11px;
  padding: 1px 6px;
}

.main-menu-text.hyper {
  color: #62f258;
}

.game-menu ul {
  padding: 25px 0 10px 0;
  margin: 0 auto;
  list-style-type: none;
  display: block;
  width: 170px;
}

.game-menu a {
  font-family: "VT323", monospace;
  font-size: 21px;
  color: #62f258;
  margin: 0 0 10px 0;
  display: block;
}

.game-menu.hyper a {
  color: #fd56e5;
}

.main-nav {
  background: #1e1e1e;
}

.main-nav h1.ui.header {
  color: #fff;
  margin: 5px 0 0;
  text-align: center;
}

.mailchimpy-thing {
  position: absolute;
  left: -5000px;
}

.main-nav h2.ui.header {
  color: #fd56e5;
  text-align: center;
  margin: 0 0 30px;
  padding: 0 8px;
}

.son-logo {
  margin-bottom: 25px;
}

.ui.header,
.ui.header span {
  font-family: "VT323", monospace;
}

.ui.inverted.attached.segment,
.ui.segment {
  border-radius: 0;
  border: 0;
}

.ui.inverted.attached.segment {
  padding: 40px;
}

.ui.inverted.red.segment {
  background: #fe0000 !important;
  color: #000 !important;
}

.ui.inverted.discord.segment {
  background: #7289da !important;
  color: #fff !important;
}

.ui.inverted.patreon.segment {
  background: #fd56e5 !important;
  color: #fff !important;
}

.ui.inverted.red.segment a {
  color: #fff;
}

.ui.inverted.yellow.segment {
  background: #ffff00 !important;
  color: #000 !important;
}

.ui.inverted.green.segment {
  background: #00ff01 !important;
  color: #000 !important;
}

.ui.inverted.blue.segment {
  background: #00ffff !important;
  color: #000 !important;
}

.ui.inverted.segment .ui.header {
  text-transform: uppercase;
  margin: 0 0 2px 0;
  color: #000;
}

.ui.header.main-heading {
  line-height: 0.85;
  padding-top: 5px;
  margin-bottom: 0px;
}

.ui.header.main-heading span {
  color: #fd56e5;
}

p.intro {
  padding: 12px 0 0px;
}

.ui.inverted.segment.black .ui.header,
.ui.inverted.segment.red .ui.header,
.ui.inverted.segment.discord .ui.header,
.ui.inverted.segment.patreon .ui.header {
  color: #fff;
}

.ui.inverted.yellow.segment .ui.header {
  color: #000;
}

.ui.inverted.segment h1.ui.header {
  font-size: 38px;
}

.ui.inverted.segment h2.ui.header {
  font-size: 32px;
  opacity: 0.8;
}

.ui.inverted.segment h3.ui.header {
  font-size: 26px;
  opacity: 0.6;
  padding-top: 15px;
}

.ui.grid.games-photos {
  padding-top: 15px;
}

#instafeed {
  padding-top: 30px;
}

.ui.grid > #instafeed.row > .column {
  padding-bottom: 1.5rem;
}

.ui.grid.bio-photos {
  padding-bottom: 20px;
}

.son-icon-email {
  margin: 0 0 20px;
}

img.ui.bordered.image,
#instafeed img {
  border: #fd56e5;
  border-style: ridge;
  border-width: 12px;
  width: 100%;
}

#instafeed > div:first-child,
.instafeed__item__overlay {
  display: none;
}

.email-label,
.checkbox.subfield {
  font-size: 18px;
}

label.checkbox.subfield span {
  margin-left: 15px;
}

.ui.small.image.son-icons {
  padding-bottom: 35px;
}

.ui.input {
  border: #62f258;
  border-style: ridge;
  border-width: 6px;
  font-family: "VT323", monospace;
  font-size: 25px;
  text-transform: uppercase;
}

.ui.input.required.email {
  text-transform: none;
}

.ui.button {
  border: #62f258;
  background: #62f258;
  border-style: ridge;
  border-width: 6px;
  font-family: "VT323", monospace;
  font-size: 25px;
  text-transform: uppercase;
  padding: 10px;
}

.ui.inverted.patreon.segment a.ui.button.patreon {
  background-color: #333;
  text-decoration: none;
  padding: 15px;
}

.listen-now-buttons .ui.button {
  margin: 0 15px 15px;
}

.content__gdpr fieldset {
  border: none;
}

.email-terms {
  font-size: 10px;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: 10px;
}

#views h2,
#views h3,
#views h4,
#xmas h2,
#xmas h3,
#xmas h4,
#live h2,
#live h3,
#live h4,
#latest h1,
#latest h2,
#latest h3 {
  margin: 0;
  font-family: "VT323", monospace;
  text-transform: uppercase;
  line-height: 1.1;
}

#live h1,
#live h2,
#live h3 {
  color: #fff;
}

#views h3 {
  color: #97eeea;
}

#views h2,
#live h2 {
  font-size: 28px;
}

#views h3,
#live h3 {
  font-size: 22px;
}

#views h4,
#live h4 {
  font-size: 22px;
}

#views p,
#live p {
  line-height: 1.1;
  padding-top: 10px;
}

#bio a,
.ui.inverted.discord.segment a,
.ui.inverted.patreon.segment a {
  color: #fff;
  text-decoration: underline;
}

.ui.inverted.purple.segment {
  background-color: #8b8cfc !important;
}

.ui.inverted.purple.segment .ui.header {
  color: #fff;
}
